@import '../variables';
@import 'mixins';

@include keyframes(slide-in) {
  0% {
    transform: translateY(64px);
  }
  100% {
    transform: translateY(0);
  }
}
