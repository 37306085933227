@import '../variables';
@import '../lib/extends';

.button {
  @extend %button;

  &.button-flat {
    @extend %button--flat;
    background: none !important;
    color: $font-color-subdued !important;
  }
}
