@import './styles/variables';

@import './styles/normalize';

@import './styles/markdown';

@import '@angular/cdk/overlay-prebuilt.css';

// Components
@import './styles/lib/mixins';
@import './styles/lib/extends';
@import './styles/lib/animations';
@import './styles/components/fonts';
@import './styles/components/typography';
@import './styles/components/button';
@import './styles/components/dropdown';
@import './styles/components/forms';

// Main stylesheet
@import './styles/main';

@import "~@ng-select/ng-select/themes/default.theme.css";

.mat-dialog-container {
    background-color: #fff;
    border: 1px solid #d7e1ec;
    border-radius: .3125rem!important;
  }
