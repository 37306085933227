$screen-login-large: 30rem;
$feedback-list-height: 28rem;

// Animation

$transition--easing: cubic-bezier(0.4, 0, 0.2, 1);
$transition--short: 0.15s;
$transition--medium: 0.25s;
$transition--long: 0.35s;

// Colors

$black: #212121;

$grey: #D3DCE6;
$light-grey: #d7e1ec;
$extra-light-grey: #EFF2F7;

$grey-50: #F7F7F7;
$grey-100: #F5F5F5;
$grey-200: #EEEEEE;
$grey-300: #E0E0E0;
$grey-400: #BDBDBD;
$grey-500: #9E9E9E;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$color-border: $grey-200;
$color-border-hover: $grey-400;
$color-border-dark: $grey-300;
$color-border-dark-hover: $grey-400;

$color-input: $grey-300;

$white: #fff;

$accent-1: #71D100;
$accent-2: #1CA5F6;
$accent-3: #D91B35;
$accent-4: #FFD100;
$bright-sky-blue: #03b7f2;
$pale-grey: #f9fafc;
$cadet-grey: #a2b0c3;
$light-yellow: #FFFBED;
$shaded-yellow: #E8E1C3;

$highlight: $accent-4;
$highlight-background-color: $light-yellow;
$highlight-border-color: $shaded-yellow;

$apple-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$color-primary: $bright-sky-blue;

$line-height-tight: 1.2em;
$line-height: 1.4em;
$line-height-loose: 1.6em;

$color-icon: $grey-400;
$conflux-primary-color: $color-primary;
$background-color: $white;
$default-font-color: $grey-700;
$color-placeholder: $grey-400;
$body-text-link: $grey-500;
$body-text-link-hover: $grey-600;
$border-color: $light-grey;
$border-color-focus: darken($border-color, 10%);
$background-highlight: darken($background-color, 3%);

// Font Colors
$color-text: $default-font-color;
$font-color-dark: $grey-900;
$font-color-subdued: $default-font-color;
$font-color-muted: $grey-500;
$font-color-placeholder: $font-color-muted;

$color-heading: $grey-900;

// Button Colors
$color-button-text: $white;
$color-button-primary: $color-primary;
$color-button-primary-hover: $color-primary;
$color-button-secondary-text: $grey-700;
$color-button-secondary: $white;
$color-button-secondary-border: $grey-400;

// Spacing
$spacing-xxx-small: 2px; // 2px
$spacing-xx-small: 4px; // 4px
$spacing-x-small: 8px; // 8px
$spacing-small: 12px; // 12px
$spacing-medium: 16px; // 16px
$spacing-large: 24px; // 24px
$spacing-x-large: 32px; // 32px
$spacing-xx-large: 48px; // 48px

$status-icon-spacing: 6px;

// Font Settings
$default-font-weight: 400;
$default-font-size: 15px;
$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: $default-font-weight;
$cards-lines-to-show: 4;


// Font Sizes
$font-size-text-xx-small: 0.625em; // 10px
$font-size-text-x-small:  0.75em; // 12px
$font-size-text-small:    0.875em; // 14px
$font-size-text-medium:   1em; // 16px
$font-size-text-large:    1.125em; // 18px
$font-size-text-x-large:  1.25em; // 20px

$font-size-heading-xx-small:   0.625em; // 10px
$font-size-heading-x-small:    0.75em; // 12px
$font-size-heading-small:      0.875em; // 14px
$font-size-heading-medium:  1.125em; // 18px
$font-size-heading-large:  1.125em; // 18px
$font-size-heading-x-large:  2em; // 32px

$font-size-small-caps:    0.75rem; // 12px

// Button Heights
$button-height-x-small: rem(28px);
$button-height-small: rem(32px);
$button-height-medium: rem(40px);
$button-height-large: rem(48px);
$button-height-x-large: 3.5em; // 56px


$max-wrapper-size: 80rem;
$border-softer: 0.1875rem; // 3px
$border-soft: 0.3125rem; // 5px
$border-round: $spacing-x-large;
$transition-speed: 0.3s;

// Header
$header-nav-font-size: 1em;
$header-nav-font-color: $default-font-color;
$header-nav-font-color-active: $default-font-color;
$header-nav-font-color-hover: $default-font-color;

// Feedback status colors
$feedback-status-new: $accent-3;
$feedback-status-open: $accent-2;
$feedback-status-assigned: $accent-1;

// Idea status colors
$idea-status-todo: $accent-3;
$idea-status-doing: $accent-2;
$idea-status-done: $accent-1;

//$box-shadow: 0px 1px 1px rgba(60, 72, 88,0.1);
$box-shadow-small: 0px 1px 1px rgba(0, 0, 0, 0.1);
$box-shadow-small-hover: 0px 3px 6px rgba(0, 0, 0, 0.12);

// New Variables (Casper added)
$layout-width-small: 720px;
$layout-width-large: 1080px;

$font-default: $apple-system;

$hover-grey-change: 10%;
$hover-color-change: 10%;
$faden-color-change: 10%;
$highlight-color: #F9FAFC;
$border-radius: 3px;
$chip-border-radius: 12px;

$box-shadow: 0px 1px 2px 0 rgba(60, 72, 88,0.1);

$color-primary: #03B7F2;
$color-icon: $grey-500;
$color-icon-hover: $grey-600;
$color-default-text: #8492A6;
$color-muted-text: #6F767E;
$color-default-text-hover: darken($color-default-text, $hover-grey-change);
$color-default-text-faded: lighten($color-default-text, $faden-color-change);
$color-default-border: #D3DCE6;

$main-wrapper-background-color: #F9FAFC;

$main-menu-color-text: $color-default-text;
$main-menu-color-text-hover: $color-default-text-hover;
$main-menu-color-text-hover: $default-font-color;

$widget-sentiment-feedback-slider-background-color: #D3DCE6;
$widget-graph-chip-background-color: #D3DCE6;

$filter-chip-background-color: #D3DCE6;

$button-color: $color-default-border;
$button-color-hover: darken($button-color, $hover-grey-change);
$button-color-text: $color-default-text;
$button-color-text-hover: $color-default-text-hover;
$button-color-primary: $color-primary;
$button-color-primary-hover: darken($button-color-primary, 5%);
$button-color-primary-text: $white;
$button-color-primary-text-hover: $white;
$button-color-primary-disabled: lighten($button-color-primary, $hover-color-change);
$button-color-primary-text: #FFFFFF;
$button-color-secondary: $color-border;
$button-color-secondary-hover: darken($color-border, 5%);
$button-color-secondary-text: $font-color-subdued;
$button-color-secondary-text-hover: $font-color-dark;

$placeholder-color: $color-placeholder;

$reply-background-color: lighten($color-primary, 45%);

$status-new-color: #D91B35;
$status-open-color: #1CA5F6;
$status-assigned-color: #71D100;


$breakpoints: (
  small : 480px,
  medium: 700px, // Previously 640px
  large : 1024px
);
