$font-size-heading-1: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-heading-2: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-heading-3: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-heading-4: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-heading-5: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-heading-5: (
  null  : 18px,
  small : 16px,
  medium : 17px,
  large: 19px
);

$font-size-body: (
  null: 15px,
  small: 16px,
  medium: 17px,
  large: 19px
);

h1, h2, h3,
h4, h5,
h6 {
  color: $font-color-dark;
  font-weight: $font-weight-bold;
}

h1, .heading-1 {
  @include font-size($font-size-heading-1);
}

h2, .heading-2 {
  @include font-size($font-size-heading-2);
}

h2, .heading-3 {
  @include font-size($font-size-heading-3);
}

.heading-1, .heading-2,
.heading-3, .heading-3,
.heading-5, .heading-6 {
  color: $font-color-dark;
}

strong {
  font-weight: $font-weight-bold;
}
