@import '../variables';
@import '../lib/mixins';

.dropdown-menu {
  display: none;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  border-radius: $border-radius;
  border: 1px solid $color-border-dark;
  box-shadow: $box-shadow-small;
  text-align: left;
  max-width: rem(220);
  background-color: $white;
  z-index: 100;
  line-height: 1;

  &.dropdown-menu-left-edge {
    left: calc(100% + #{$spacing-small});
  }

  &:hover {
    box-shadow: $box-shadow-small-hover;
  }
}

.dropdown-menu-items {
  list-style: none;
  margin: 0;
}

.dropdown-menu-item {
  border-bottom: 1px solid $color-border;

  a {
    display: block;
    color: $font-color-subdued;
    padding: $spacing-small;
  }

  a:hover {
    background-color: $grey-50;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}


