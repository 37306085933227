@import '../variables';
@import '../lib/mixins';

%boxed-container {
  background: $white;
  border: 1px solid $border-color;
}

%boxed-container-shadow {
  @extend %boxed-container;
  box-shadow: $box-shadow-small;
}

%button, .button {
  padding: 9px 12px;
  outline: 0;
  text-align: center;
  color: $button-color-primary-text;
  color: var(--color-primary-text);
  border: 1px solid transparent;
  font-weight: $font-weight-medium;
  border-radius: $border-radius;
  font-weight: $font-weight-medium;
  background: $color-primary;
  background: var(--color-primary);
  cursor: pointer;
  white-space: nowrap;
  transition: $transition--medium $transition--easing background-color,
              $transition--medium $transition--easing border-color;

  &:hover {
    background-color: $button-color-primary-hover;
    background-color: var(--color-primary-dark);
  }

  &[disabled] {
    opacity: 0.5;
  }
}

%button--secondary {
  @extend %button;
  color: $font-color-dark;
  background-color: none;
  border-color: $color-border;

  &:hover {
    border-color: $button-color-secondary-hover;
  }
}

%button--large,
.button-large {
  @extend %button;
  padding: 15px 16px;
}

%button--icon,
.button-icon {
  @extend %button--secondary;
  border: none;
  position: relative;
  background: none;
  padding: $spacing-small;

  &:hover {
    background: none;

    cnflx-icon svg {
      fill: $color-icon-hover;
    }
  }
}

%button--text-icon,
.button-text-icon {
  @extend %button--reset;
  border-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
  padding: $spacing-small;
  word-wrap: no-wrap;

  cnflx-icon {
    margin-right: $spacing-xx-small;
  }

  &:hover {
    background: none;

    cnflx-icon svg {
      fill: $color-icon-hover;
    }
  }
}

%button--flat {
  background: none;
  color: $font-color-dark;
}

%button--reset {
  @extend %button;
  color: $font-color-dark;
  background: none;
  outline: 0;
  border: transparent;
}

%dropdown {
  @extend %button--reset;
  position: relative;

  &:hover {
    background: none;
    border: none;
  }

  &.has-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  cnflx-icon {
    margin-left: $spacing-xx-small;
    margin-top: 1px;
  }

  .dropdown-menu-item a {
    transition: $transition--short $transition--easing background-color;
  }
}
