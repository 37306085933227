@import 'variables';
@import './lib/mixins';
@import './lib/extends';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

[hidden], .hidden {
  display: none !important;
}

:focus {
  outline: none;
}

html, body {
  background: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include font-size($font-size-body);
  font-family: $font-default;
  line-height: rem(24);
  font-weight: $default-font-weight;
  color: $default-font-color;
  overflow-x: hidden;

  --color-primary: $color-primary;
  --color-primary-light: lighten($color-primary, 5%);
  --color-primary-dark: darken($color-primary, 5%);
  --color-primary-text: $button-color-primary-text;
}

body, a, button, select, input, texarea, p, span {
  letter-spacing: .01em;
  word-spacing: .02em;
}

a {
  cursor: pointer;
}


p {
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: $conflux-primary-color;
}

img {
  width: 100%;
  display: block;
}

button,
optgroup,
select,
textarea {
  font-family: $apple-system;
}

.noscroll {
  overflow: hidden;
}

.checkbox-container input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
	width: 0.85rem;
	height: 0.85rem;
	border-radius: $border-soft;
	cursor: pointer;
	border: 1px solid #d4dadf;
  margin: 0 0.25rem 0 0;
  transition: $transition-speed;
  outline: 0;
  position:relative;
  top:2px;
}

.checkbox-container label {
  display: inline-block;
  cursor: pointer;
  font-size: 1em;
}

.checkbox-container input:checked {
  background: $conflux-primary-color;
  border: 1px solid $conflux-primary-color;
}

.wrapper {
  max-width: $layout-width-large;
  margin: 0 auto;
}

.container {
  padding: 0 $spacing-medium;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.flex-1 {
    flex: 1;
  }
  &.no-flex {
    flex: 0;
  }
  &.flex-end {
    align-items: flex-end;
  }
  &.flex-2 {
    flex: 2;
  }
}

.justify-space-between {
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

//
// Filter
//

.status {
  &.status-todo {
    color: $idea-status-todo;
    svg {
      fill: $idea-status-todo;
    }
  }

  &.status-doing {
    color: $idea-status-doing;
    svg {
      fill: $idea-status-doing;;
    }
  }

  &.status-done {
    color: $idea-status-done;
    svg {
      fill: $idea-status-done;
    }
  }
}

// Cookie consent
.cc-window {
  border-top: 1px solid $color-default-border;
}

.cc-revoke {
  display: none !important;
}

.cdk-overlay-pane.overflow-visible .mat-dialog-container {
  overflow: visible;
}

simple-notifications {
  z-index: 1001;
  position: absolute;
}


// Sticky header

.sticky-header,
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  border-bottom: 1px solid $color-border;
  display: block;
  z-index: 9999;

  &.has-actions {
    h1 {
      flex-grow: 1;
    }

    cnflx-icon {
      width: rem(24);
      height: rem(24);
    }
  }
}

.sticky-header-container,
.header-container {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 $spacing-medium;
}

.header {
  position: relative;
}
