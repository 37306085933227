input {
  font-family: $font-default;

  @include placeholder {
    color: $font-color-placeholder;
  }
}

textarea, select {
  display: block;
  border: 1px solid $color-default-border;
  outline: 0;
  color: $color-placeholder;
  padding: 8px;
  border-radius: $border-radius;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out;

  &::placeholder {
    color: $placeholder-color;
  }
}

textarea {
  padding: $spacing-medium;
}

cnflx-text-input + cnflx-text-input {
  margin-top: 16px;
}
