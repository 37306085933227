@font-face {
  font-family: 'SFUI';
  src: url('/assets/fonts/SFUIText-Semibold.eot');
  src: url('/assets/fonts/SFUIText-Semibold.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/SFUIText-Semibold.woff2') format("woff2"), url('/assets/fonts/SFUIText-Semibold.woff') format("woff"), url('/assets/fonts/SFUIText-Semibold.ttf') format("truetype"), url('/assets/fonts/SFUIText-Semibold.svg#SFUIText-Semibold') format("svg");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'SFUI';
  src: url('/assets/fonts/SFUIText-Medium.eot');
  src: url('/assets/fonts/SFUIText-Medium.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/SFUIText-Medium.woff2') format("woff2"), url('/assets/fonts/SFUIText-Medium.woff') format("woff"), url('/assets/fonts/SFUIText-Medium.ttf') format("truetype"), url('/assets/fonts/SFUIText-Medium.svg#SFUIText-Medium') format("svg");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'SFUI';
  src: url('/assets/fonts/SFUIText-Regular.eot');
  src: url('/assets/fonts/SFUIText-Regular.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/SFUIText-Regular.woff2') format("woff2"), url('/assets/fonts/SFUIText-Regular.woff') format("woff"), url('/assets/fonts/SFUIText-Regular.ttf') format("truetype"), url('/assets/fonts/SFUIText-Regular.svg#SFUIText-Regular') format("svg");
  font-weight: normal;
  font-style: normal;
}
